@font-face {
  src: url("../../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

@font-face {
  src: url("../../../fonts/alethiapro-italic.otf");
  font-family: "Alethia Pro Italic";
}

.bannerContainer {
  display: grid;
  grid-template-columns: 39.2% 55.7%;
  padding: 0 11% 0 11%;
  justify-content: space-between;
  margin-bottom: 200.28px;
  position: relative;
}

.bannerLogo {
  margin-top: 149px;
}

.bannerHeader {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin-bottom: 40px;
}

.logo {
  width: 100%;
  margin-bottom: 61px;
}

.bannerTextContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 39.71px;
}

.bannerText {
  font-family: "Roboto Regular", Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.bannerText:last-of-type {
  margin-bottom: 0;
}

.bannerPic {
  width: 100%;
  height: 100%;
}

.completedExhibition {
  position: absolute;
}

.completedHeader {
  font-family: "Alethia Pro Italic";
  font-size: 40px;
  line-height: 54px;
  font-weight: 300;
  color: #207B44;
  margin: 0;
}

.completedText {
  font-family: "Roboto Regular";
  font-size: 24px;
  line-height: 54px;
  color: #207B44;
}
