@font-face {
  src: url("../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

.catsContainer {
  margin-top: 156px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 11% 0 11%;
  box-sizing: border-box;
  margin-bottom: 383px;
}

.catCard {
  width: 432.2px;
  border: none;
  margin-bottom: 100px;
}

.catCard img {
  width: 100%;
  height: 355px;
  object-fit: cover;
  margin-bottom: 45px;
  border-radius: 24px;
}

.catText {
  font-family: "Roboto Regular";
  font-size: 24px;
  line-height: 54px;
}
