@font-face {
  src: url("../../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

@font-face {
  src: url("../../../fonts/alethiapro-italic.otf");
  font-family: "Alethia Pro Italic";
}

.FAQContainer {
  padding: 0 11% 0 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.FAQHeader {
  font-family: "Alethia Pro Italic";
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 80px;
  font-weight: 300;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
}

.textAndButtonsContainer {
  width: 44.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.FAQText {
  font-family: "Roboto Regular";
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.FAQButton {
  width: 100%;
  height: 76.53px;
  background-color: transparent;
  border-radius: 24px;
  border: solid #79bd9a 4px;
  font-family: "Roboto Regular";
  font-size: 24px;
  line-height: 20px;
}

.FAQButton:hover {
  border: solid black 4px;
}

.FAQImgContainer {
  width: 45%;
  height: 93.4%;
  position: relative;
}

.FAQImg {
  width: 100%;
}

.FAQBackground {
  position: absolute;
  z-index: -1;
  height: 110%;
  top: -40px;
  left: -30px;
}

.FAQLink {
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
}

