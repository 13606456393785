@font-face {
  src: url("../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

@font-face {
  src: url("../../fonts/alethiapro-italic.otf");
  font-family: "Alethia Pro Italic";
}

.pastEventsPage {
  padding: 0 11% 0 11%;
  margin-top: 142px;
  margin-bottom: 373.67px;
}

.expCard {
    margin-bottom: 107.33px;
}

.expCard:last-of-type {
    margin-bottom: 0;
}

.expHeader {
  font-family: "Alethia Pro Italic", Arial, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 54px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 80.82px;

}

.expPhotos {
  display: flex;
  justify-content: space-between;
}

.expPhoto {
  width: 325px;
  height: 274.6px;
  object-fit: cover;
}
