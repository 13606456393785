@font-face {
  src: url("../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

.footerContainer {
  padding: 82px 11% 74px 11%;
  margin-top: auto;
  width: 100%;
  background-color: #fb8433;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.footerMenu {
  display: flex;
  flex-direction: column;
  width: 11.6%;
}

.menuLink {
  font-family: "Roboto Regular";
  font-size: 18px;
  color: black;
  text-decoration: none;
  margin-bottom: 3px;
  line-height: 27px;
}

.menuLink:last-of-type {
  margin-bottom: 0;
}

.footerContacts {
  width: 20.5%;
}

.contactsText {
  font-family: "Roboto Regular";
  font-size: 18px;
  line-height: 27px;
}

.footerHeader {
  font-family: "Roboto Regular";
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 34px;
  line-height: 36px;
  font-weight: 300;
}

.socialNetworks {
  width: 17.5%;
}

.networksHeader {
  font-family: "Roboto Regular";
  font-size: 20px;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 34px;
  font-weight: 300;
}

.iconsContainer {
  display: flex;
  justify-content: space-between;
}

.iconLink {
  width: 25%;
}

.icon {
  width: 100%;
}

.askQuestion {
  width: 20.04%;
}

.phoneNumber {
  color: black;
  text-decoration: none;
  font-family: "Roboto Regular";
  font-size: 24px;
  line-height: 54px;
}

.callButton {
  width: 100%;
  height: 78px;
  background-color: transparent;
  border-radius: 24px;
  border: solid black 4px;
  font-family: "Roboto Regular";
  font-size: 20px;
  margin-top: 30.88px;
  line-height: 20px;
}
