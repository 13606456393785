@font-face {
  src: url("../../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

@font-face {
  src: url("../../../fonts/alethiapro-italic.otf");
  font-family: "Alethia Pro Italic";
}

.participantsContainer {
  padding: 0 11% 0 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 159.72px;
}

.participantsHeader {
  margin-top: 0;
  margin-bottom: 80px;
  font-family: "Alethia Pro Italic";
  font-size: 40px;
  font-weight: 300;
}

.participantsMenu {
  display: flex;
  justify-content: space-between;
}

.participantsCats {
  width: 44.8%;
}

.participantsDogs {
  width: 44.8%;
}

.participantsButton {
  width: 100%;
  height: 77px;
  background-color: #fb8433;
  border-radius: 24px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 32px;
  margin-bottom: 29px;
}

.participantsLink {
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
}

.participantsLink:hover {
  color: white;
}

.participantsImg {
  width: 100%;
}
