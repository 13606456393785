@font-face {
  src: url("../../../fonts/Roboto-Regular.ttf");
  font-family: "Roboto Regular";
}

.pastEvents_container {
  padding: 0 11% 0 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150.87px;
}

.pastEvents_header {
  font-family: "Alethia Pro Italic", Arial, Helvetica, sans-serif;
  font-size: 40px;
  margin-top: 0;
  font-weight: 300;
}

.pastEvents_button {
  width: 45%;
  height: 77px;
  background-color: #fb8433;
  border-radius: 24px;
  border: none;
  font-family: "Roboto Regular";
  font-size: 32px;
  margin-top: 80px;
}

.pastEvents_link {
  text-decoration: none;
  color: black;
  display: inline-block;
  width: 100%;
}

.pastEvents_link:hover {
  color: white;
}
