.container {
  width: 100%;
}

.classForThreadActive {
  background-size: 1500px 4600px;
  background-repeat: no-repeat;
  background-position: center 350px;
}

.classForThreadCompleted {
  background-size: 1500px 5600px;
  background-repeat: no-repeat;
  background-position: center 350px;
}
