.carousel {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.carousel__list {
  display: flex;
  list-style: none;
  position: relative;
  width: 100%;
  height: 379px;
  justify-content: center;
  perspective: 300px;
}

.carousel__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38.4%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5);
  position: absolute;
  transition: all 0.3s ease-in;
}

.carousel__item[data-pos="0"] {
  z-index: 8;
}

.carousel__item[data-pos="1"],
.carousel__item[data-pos="-1"] {
  z-index: 7;
}

.carousel__item[data-pos="2"],
.carousel__item[data-pos="-2"] {
  z-index: 6;
}

.carousel__item[data-pos="3"],
.carousel__item[data-pos="-3"] {
  z-index: 5;
}

.carousel__item[data-pos="4"],
.carousel__item[data-pos="-4"] {
  z-index: 4;
}

.carousel__item[data-pos="5"],
.carousel__item[data-pos="-5"] {
  z-index: 3;
}

.carousel__item[data-pos="6"],
.carousel__item[data-pos="-6"] {
  z-index: 2;
}

.carousel__item[data-pos="7"],
.carousel__item[data-pos="-7"] {
  z-index: 1;
}


.carousel__item[data-pos="-1"],
.carousel__item[data-pos="1"] {
  opacity: 0.7;
  filter: blur(1px) grayscale(10%);
}

.carousel__item[data-pos="-1"] {
  transform: translateX(-40%) scale(0.9);
}

.carousel__item[data-pos="1"] {
  transform: translateX(40%) scale(0.9);
}

.carousel__item[data-pos="-2"],
.carousel__item[data-pos="2"],
.carousel__item[data-pos="-3"],
.carousel__item[data-pos="3"],
.carousel__item[data-pos="-4"],
.carousel__item[data-pos="4"],
.carousel__item[data-pos="-5"],
.carousel__item[data-pos="5"],
.carousel__item[data-pos="-6"],
.carousel__item[data-pos="6"],
.carousel__item[data-pos="-7"],
.carousel__item[data-pos="7"] {
  opacity: 0.4;
  filter: blur(3px) grayscale(20%);
}

.carousel__item[data-pos="-2"],
.carousel__item[data-pos="-3"],
.carousel__item[data-pos="-4"],
.carousel__item[data-pos="-5"],
.carousel__item[data-pos="-6"],
.carousel__item[data-pos="-7"] {
  transform: translateX(-70%) scale(0.8);
}

.carousel__item[data-pos="2"],
.carousel__item[data-pos="3"],
.carousel__item[data-pos="4"],
.carousel__item[data-pos="5"],
.carousel__item[data-pos="6"],
.carousel__item[data-pos="7"] {
  transform: translateX(70%) scale(0.8);
}

.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
 }

