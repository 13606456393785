@font-face {
    src: url("../../fonts/Roboto-Regular.ttf");
    font-family: "Roboto Regular";
  }
  
  .dogsContainer {
    margin-top: 156px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 11% 0 11%;
    box-sizing: border-box;
    margin-bottom: 383px;
  }
  
  .dogCard {
    width: 432.2px;
    border: none;
    margin-bottom: 100px;
  }
  
  .dogCard img {
    width: 100%;
    height: 355px;
    object-fit: cover;
    margin-bottom: 45px;
    border-radius: 24px;
  }
  
  .dogText {
    font-family: "Roboto Regular";
    font-size: 24px;
    line-height: 54px;
  }